import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styles from './SiteList.module.scss'
import { useApi } from '../../utils/api'
import { FaCog, FaGithub, FaEye } from 'react-icons/fa'

export default () => {
  const {
    loading,
    data,
    error
  } = useApi('/netlify/sites')

  return (
    <section>
      <div className='container'>
        <h3>Builduð verkefni</h3>

        { loading && (
          <Skel />
        )}

        {error && (
          <h1 style={{ color: 'red' }}>Error! {error.toString()}</h1>
        )}

        {data && (
          <List sites={data} />
        )}
      </div>
    </section>
  )
}

const List = ({ sites }) => {
  return (
    <div className='row row-cols-md-2 row-cols-xl-3'>
      {sites.map(site => {
        const passwordLine = site.password ? `p: ${site.password}` : '\u00A0'

        return (
          <div key={site.id} className='col'>
            <Item
              name={site.name}
              password={passwordLine}
              sslUrl={site.ssl_url}
              adminUrl={site.admin_url}
              repoUrl={site.build_settings.repo_url}
            />
          </div>
        )
      })}
    </div>
  )
}

const Item = ({ repoUrl, adminUrl, sslUrl, name, password }) => (
  <div className={styles.panel}>
    <h6>{name}</h6>

    <p className={styles.pwd}>
      {password}
    </p>

    <div className={styles.iconBar}>
      <a
        title='Opna vef'
        target='_blank'
        href={sslUrl}
      >
        <FaEye />
      </a>

      <a
        title='Opna í netlify'
        target='_blank'
        href={adminUrl}
      >
        <FaCog />
      </a>

      <a
        title='Opna github repo'
        target='_blank'
        href={repoUrl}
      >
        <FaGithub />
      </a>
    </div>
  </div>
)

const Skel = () => (
  <div className='row row-cols-md-2 row-cols-xl-3'>
    {new Array(6).fill(1337).map((x, i) => (
      <div key={i} className='col'>
        <div className={styles.panel}>
          <h6><Skeleton /></h6>

          <p className={styles.pwd}>
            <Skeleton width='80px' />
          </p>

          <div className={styles.iconBar}>
            <Skeleton width='120px' />
          </div>
        </div>
      </div>
    ))}
  </div>
)
