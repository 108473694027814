import React from 'react'
import { Router } from '@reach/router'
import Main from '../../layouts/Main'
import { Transition } from '../../components/'
import SiteList from '../../views/SiteList'
import { Private } from '../../utils/authentication'

export default () => {
  return (
    <Private>
      <Main>
        <Transition>
          <Router>
            <SiteList path='/sites' />
          </Router>
        </Transition>
      </Main>
    </Private>
  )
}
